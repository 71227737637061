import { makeStyles } from "@gfg/ui-v2/theming";
import ContainerShadow from "./container-shadow";

const useStyles = makeStyles<StylingProps>()((theme, { rounded }) => ({
  container: {
    background: theme.colors.background,
    borderRadius: rounded ? theme.spacing(10) : undefined,
  },
  header: {
    padding: theme.spacing(15),
    borderBottom: `1px solid ${theme.palette.tertiary[200]}`,
  },
  content: {
    padding: theme.spacing(15),
  },
  title: {
    fontWeight: theme.typography.fontWeights.semiBold,
    fontSize: theme.typography.h2.fontSize,
    lineHeight: theme.typography.h2.lineHeight,
    margin: 0,
  },
}));

interface ContainerShadowProps extends StylingProps {
  children: React.ReactNode;
  className?: string;
  headerTitle?: string;
}

interface StylingProps {
  rounded?: boolean;
}

export default function ContentContainer({ children, headerTitle, className, ...stylingProps }: ContainerShadowProps) {
  const { classes, cx } = useStyles(stylingProps);

  return (
    <ContainerShadow className={cx(classes.container, className)}>
      {headerTitle ? (
        <div className={classes.header}>
          <h2 className={classes.title}>{headerTitle}</h2>
        </div>
      ) : null}
      <div className={classes.content}>{children}</div>
    </ContainerShadow>
  );
}
