import { InputField } from "@gfg/ui-v2/components/input";
import { PreviewItem } from "@coral/typings";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import TableFooter from "../../../../../common/components/table/table-footer";
import Spacing from "../../../../../common/components/spacing";
import FlexWrapContainer from "../../../../../common/components/containers/flex-wrap-container";
import FeedPreviewItem from "../preview-item";
import makePreviewRows from "~/feed/components/feed-preview/utils/make-preview-rows";

const useStyles = makeStyles()(({ spacing, colors, palette, typography }) => ({
  inputContainer: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1fr",
    gap: spacing(80),
    width: "100%",
  },
  configSummaryContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "100%",
    background: palette.gray[100],
    color: colors.darkgray,
    padding: spacing(20),
    borderRadius: spacing(10),
  },
  configSummaryHeadline: {
    fontWeight: "bolder",
  },
  resultHint: {
    marginTop: spacing(-15),
    color: colors.gray,
  },
  chosen: {
    marginBottom: spacing("md"),
  },
}));

interface PreviewBodyProps {
  sku: string;
  onSKUChange?(query: string): void;

  uuid: string;
  onUuidChange?(uuid: string): void;

  items: PreviewItem[];

  chosenStrategies: string[];
  total: number;
  loading: boolean;

  offset: number;
  limit: number;
  onLimitChange(limit: number): void;
  onOffsetChange(offset: number): void;
}

export default function PreviewBody({
  sku,
  onUuidChange,
  uuid,
  total,
  onSKUChange,
  items,
  limit,
  offset,
  loading,
  onLimitChange,
  onOffsetChange,
  chosenStrategies,
}: PreviewBodyProps) {
  const { classes } = useStyles();

  return (
    <>
      <div>
        {chosenStrategies?.length ? (
          <div className={classes.chosen}>
            <strong>Chosen strategies:</strong> {chosenStrategies.join(", ")}
          </div>
        ) : null}
      </div>
      <div className={classes.inputContainer}>
        {onSKUChange && <InputField value={sku} onChange={onSKUChange} block placeholder="Enter SKU" />}
        {onUuidChange && <InputField value={uuid} onChange={onUuidChange} block placeholder="Enter UUID" />}
      </div>
      <p className={classes.resultHint}>
        {total ? (
          <>
            Found <b>{total}</b> result items.
          </>
        ) : (
          <>No results found</>
        )}
      </p>
      <Spacing top={10} />
      {loading && <LinearProgress indeterminate />}

      <Spacing top={20} />

      <FlexWrapContainer>
        {items?.map(item => (
          <FeedPreviewItem
            key={item.id}
            id={item.id}
            values={makePreviewRows(item)}
            imagePath={item?.images[0] || ""}
          />
        ))}
      </FlexWrapContainer>

      <Spacing top={20} />

      <TableFooter
        limit={limit.toString()}
        offset={offset}
        total={total}
        onLimitChange={onLimitChange}
        onOffsetChange={onOffsetChange}
      />
    </>
  );
}
