/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback } from "react";
import { InputField } from "@gfg/ui-v2/components/input";
import { Option, SelectField } from "@gfg/ui-v2/components/select";
import { CheckIcon } from "@gfg/ui-v2/icons";
import { Radio, RadioGroup } from "@gfg/ui-v2/components/radio";
import { Controller, useForm, FormProvider, useWatch, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import CustomFeedStateMap from "common/constants/custom-feed-state";
import OrderStrategy from "../../../common/constants/order-strategy";
import newsletterValidationSchema from "./newsletter-validation-schema";
import NewsletterCreateActions from "./newsletter-header-actions";
import ErrorMessage from "../../../common/components/error-message";
import SectionTitle from "../../../common/components/recommendations/section-title";
import SectionLabel from "../../../common/components/recommendations/section-label";
import { ConditionalFilterFormValue } from "~/common/components/form-filters/conditional-filters";
import useStyles from "./use-styles";
import FeedStrategy from "../../../common/constants/feed-strategy";
import {
  CustomFeedStateTranslations,
  FeedStrategyTranslations,
  OrderStrategyTranslations,
} from "~/common/translations";
import preventSubmitOnEnter from "../../../common/utils/form/prevent-submit-on-enter";
import useEventTracking from "../../../common/hooks/use-tracking-event";
import FeedFilters from "../../../feed/components/feeds-form/feed-filters";

export interface FormData {
  newsletterName: string;
  feedStrategy: string[];
  feedFilters: ConditionalFilterFormValue[];
  orderStrategy: string;
  landingPageUrl: string;
  htmlTileTemplate: string;
  feedStatus: string;
}

export interface NewsletterFormProps {
  newsletterId?: string;
  onSubmit(formData: FormData): void;
  formTitle: string;
  defaultValues?: FormData;
  allowImmediateSubmit?: boolean;
}

export default function NewsletterForm({
  newsletterId,
  onSubmit,
  formTitle,
  allowImmediateSubmit,
  defaultValues,
}: NewsletterFormProps) {
  const { classes, cx } = useStyles();
  const sendAddFeedFilterAnalyticsEvent = useEventTracking({ eventName: "recos-nl-add-filter" });

  const methods = useForm<FormData>({
    resolver: yupResolver(newsletterValidationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      newsletterName: "",
      landingPageUrl: "",
      htmlTileTemplate: "",
      feedStrategy: [],
      feedFilters: [],
      orderStrategy: "",
      feedStatus: "Draft",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = methods;

  const handleSubmitAndResetForm: SubmitHandler<FormData> = useCallback(
    async formData => {
      await onSubmit(formData);
      reset({}, { keepValues: true });
    },
    [onSubmit, reset],
  );

  const selectedFeedStrategies = useWatch({ control, name: "feedStrategy" });

  const showOrderStrategySelectField = selectedFeedStrategies.length > 1;

  return (
    <FormProvider {...methods}>
      <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitAndResetForm)}>
        <NewsletterCreateActions
          title={formTitle}
          disabledSubmit={allowImmediateSubmit ? !isValid : !isDirty || !isValid}
          allowPreview={newsletterId !== undefined}
        />
        <div className={classes.newsletterSettings}>
          <SectionTitle
            title="General Information"
            description="This section contains fields for general information about the configuration. This information helps to identify the configuration by its name and also determines its key behavior."
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Feed name"
              description="The feed name helps to identify the configuration. This is shown in the table, and users can search for this configuration by this name."
            />
            <Controller
              name="newsletterName"
              control={control}
              render={({ field }) => (
                <InputField {...field} hint={errors.newsletterName?.message} placeholder="Newsletter name" block />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel title="Feed status" description="Status helps to identify and sort configurations" />
            <Controller
              name="feedStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  style={{ display: "flex", flexDirection: "column" }}
                  className={classes.orderStrategy}
                >
                  {Object.values(CustomFeedStateMap).map(feedStateValue => (
                    <Radio key={feedStateValue} color="primary" value={feedStateValue}>
                      {CustomFeedStateTranslations[feedStateValue]}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
            <ErrorMessage message={errors.orderStrategy?.message?.toString()} />
          </div>
          <div className={cx(classes.inputField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Feed strategy"
              description={
                <>
                  The feed strategy determines how this configuration will fetch the set of products. Users can add more
                  than one strategy here. <br />
                  <a target="_blank" href="/https://datajet.io/docs/recommendation-system/plug-and-play-strategies">
                    Read more about the feed strategy
                  </a>
                </>
              }
            />
            <Controller
              name="feedStrategy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  placeholder="Choose feed strategy"
                  block
                  multiple
                  value={value}
                  onChange={onChange}
                  hint={(errors.feedStrategy as any)?.message}
                >
                  {Object.values(FeedStrategy).map(strategyValue => (
                    <Option key={strategyValue} text={FeedStrategyTranslations[strategyValue]} value={strategyValue}>
                      <div className={classes.optionContainer}>
                        <span className={classes.optionName}>{FeedStrategyTranslations[strategyValue]}</span>
                        {value?.includes(strategyValue) && <CheckIcon className={classes.icon} />}
                      </div>
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          {showOrderStrategySelectField && (
            <div className={cx(classes.inputField, classes.verticallyMiddle)}>
              <SectionLabel
                title="Order strategy"
                description={
                  <>
                    When you add more than one Feed Strategy, the Order strategy helps to determine the order of
                    products from different strategies in the result. <br />
                    <a target="_blank" href="/docs/recommendation-system/newsletter-config#set-the-order-strategy">
                      Read more about the order strategy
                    </a>
                  </>
                }
              />
              <Controller
                name="orderStrategy"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <RadioGroup
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    style={{ display: "flex", flexDirection: "column" }}
                    className={classes.orderStrategy}
                  >
                    {Object.values(OrderStrategy).map(strategyValue => (
                      <Radio key={strategyValue} color="primary" value={strategyValue}>
                        {OrderStrategyTranslations[strategyValue]}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              />
              <ErrorMessage message={errors.orderStrategy?.message ?? ""} />
            </div>
          )}
          <SectionTitle
            title="Feed filters"
            description={
              <>
                Feed filters help us to either include or exclude a set of products based on the defined rules. When
                users add inclusive rules, only products from the defined set are shown. When users add exclusive rules,
                all the products defined by that rule are removed from the results. <br />
                <a target="_blank" href="/docs/recommendation-system/newsletter-config#integrate-feed-filters">
                  Read more about Feed filters
                </a>
              </>
            }
          />
          <FeedFilters onAddFeedFilter={sendAddFeedFilterAnalyticsEvent} />
          <SectionTitle
            title="Layout & Behaviour"
            description="This section contains fields to determine the behavior of the product tile. Product tiles are the individual blocks showing products in the newsletter"
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Landing Page URL"
              description={
                <>
                  Users will be redirected to this URL when the user clicks on the product tile in the newsletter. If
                  left blank, users will be redirected to the PDP of the SKU. <br />
                  <a target="_blank" href="/docs/recommendation-system/newsletter-config#landing-page-url">
                    Read more about the Landing Page URL
                  </a>
                </>
              }
            />
            <Controller
              name="landingPageUrl"
              control={control}
              render={({ field }) => (
                <InputField {...field} hint={errors.landingPageUrl?.message} placeholder="Link" block />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField)}>
            <SectionLabel
              title="HTML Tile Template"
              description={
                <>
                  HTML tile template is used for generating the product tiles in the newsletters. Users can customize
                  the appearance of the tiles here. <br />
                  <a target="_blank" href="/docs/recommendation-system/newsletter-config#html-tile-template">
                    Read more about the HTML Tile Template
                  </a>
                </>
              }
            />
            <Controller
              name="htmlTileTemplate"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  hint={errors.htmlTileTemplate?.message}
                  multiline
                  placeholder="HTML Tile Template"
                  block
                  rows={6}
                />
              )}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
