import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import PageContainer from "../../../common/components/containers/page-container";
import ContentContainer from "../../../common/components/containers/content-container";
import PreviewBody from "./components/preview-body";
import usePreview from "./hooks/use-preview";
import useVisiblePreviewFields from "~/common/components/recommendations/hooks/use-visible-preview-fields";
import { RequiredPreviewFields } from "~/common/constants/feed-strategy";
import { useGetFeedQuery, useGetNewsletterQuery } from "~/generated";
import reactiveVariables from "~/common/graphql/global-reactive-variables";
import { FeedStrategyTranslations } from "~/common/translations";

export default function FeedPreviewPage() {
  const { feedId, newsletterId } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data: feedData } = useGetFeedQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        id: feedId as string,
        projectId: selectedEnvironmentProjectId,
      },
    },
    skip: !feedId,
  });

  const { data: newsletterData } = useGetNewsletterQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        id: newsletterId as string,
        projectId: selectedEnvironmentProjectId,
      },
    },
    skip: !newsletterId,
  });

  const chosenStrategies = useMemo(
    () =>
      (feedData || newsletterData)?.customFeed.recipe?.feed?.map(feed => FeedStrategyTranslations[feed?.name]!) ?? [],
    [feedData],
  );

  const selectedFeedStrategies = useMemo(() => {
    if ((feedData || newsletterData)?.customFeed) {
      return (feedData || newsletterData)?.customFeed.recipe?.feed?.map(feed => feed?.name!) ?? [];
    }
    return [];
  }, [feedData, newsletterData]);

  const { showIdField, showSkuField } = useVisiblePreviewFields(selectedFeedStrategies, RequiredPreviewFields);
  const { previewData, setUuid, setSku, sku, uuid, setOffset, setLimit, offset, loading, limit } = usePreview({
    feedId: feedId || newsletterId,
    feedPreviewType: "product",
    showIdField,
    showSkuField,
  });

  return (
    <PageContainer>
      <ContentContainer headerTitle={(feedData || newsletterData)?.customFeed?.name}>
        <PreviewBody
          loading={loading}
          chosenStrategies={chosenStrategies}
          onSKUChange={setSku}
          sku={sku}
          uuid={uuid}
          onUuidChange={setUuid}
          items={previewData?.items}
          limit={limit}
          offset={offset}
          onLimitChange={setLimit}
          onOffsetChange={setOffset}
          total={previewData?.total as number}
        />
      </ContentContainer>
    </PageContainer>
  );
}
