import { PreviewItem } from "@coral/typings";

const makePreviewRows = (preview: PreviewItem): { name: string; value: string }[] => [
  {
    name: "Brand",
    value: preview.brand?.name,
  },
  {
    name: "Price",
    value: `${preview.price.previous.toString()} - ${preview.price.current.toString()} ${preview.price.currency}`,
  },
  {
    name: "Stock",
    value: preview.stock_count.toString(),
  },
  {
    name: "Version",
    value: preview.version || "No version yet",
  },
  {
    name: "Color",
    value: preview.colors?.join(", ") || "No colors",
  },
  {
    name: "Gender",
    value: preview.gender?.join(", ") || "No gender",
  },
];

export default makePreviewRows;
