import { makeStyles } from "@gfg/ui-v2/theming";
import Spacing from "../../../../../common/components/spacing";
import SkuButton from "~/common/components/sku-button";
import RowItem from "~/feed/components/feed-preview/components/preview-item/row-item";

const useStyles = makeStyles()(({ spacing, size, colors, palette, typography }) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing("sm"),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(10),
    background: colors.background,
    border: "0.5px solid #d0d5dd",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    borderRadius: spacing(4),
    padding: spacing(16),
    width: spacing(300),
  },
  imgContainer: {
    objectFit: "contain",
    height: size(240),
  },
  itemsContainer: {
    flex: 3,
    paddingLeft: spacing(12),
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing(7),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    gap: spacing(15),
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
  },
  tag: {
    cursor: "pointer",
  },
  skuItem: {
    display: "flex",
    alignItems: "center",
  },
}));

interface PreviewItemProps {
  id: string;
  values: Array<{ name: string; value: string }>;
  imagePath: string;
}

export default function PreviewItem({ id, values, imagePath }: PreviewItemProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.imgContainer} src={imagePath} alt="" />
      <Spacing top={20} />
      <div className={classes.itemsContainer}>
        <div className={cx(classes.item, classes.skuItem)}>
          <span className={classes.name}>SKU</span>
          <SkuButton value={id} />
        </div>
        {values.map(item => (
          <RowItem key={`${id}-${item.name}`} name={item.name} value={item.value} />
        ))}
      </div>
    </div>
  );
}
