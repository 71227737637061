import { useNavigate, useParams } from "react-router-dom";
import Button from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: spacing(32),
  },
  buttonsContainer: {
    "& > button": {
      marginLeft: spacing(12),
    },
  },
}));

interface NewsletterCreateActionsProps {
  disabledSubmit: boolean;
  title: string;
  allowPreview?: boolean;
}

export default function NewsletterCreateActions({ disabledSubmit, allowPreview, title }: NewsletterCreateActionsProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { region } = useParams();

  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <div className={classes.buttonsContainer}>
        <Button variant="outline" onClick={() => navigate(`/${region}/recommendations/newsletter`)}>
          Cancel
        </Button>
        <Button disabled={disabledSubmit}>Save changes</Button>
        {allowPreview && (
          <Button disabled={!disabledSubmit} onClick={() => navigate("preview")}>
            Preview Changes
          </Button>
        )}
      </div>
    </div>
  );
}
