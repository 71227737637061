import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { FeedPreviewType } from "@coral/typings";
import pagination from "../../../../common/constants/pagination";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import { useCustomFeedPreviewLazyQuery } from "../../../../generated";
import useCachedState from "~/common/hooks/use-cached-state";

interface UsePreviewProps {
  feedId: string;
  feedPreviewType: FeedPreviewType;
  showIdField?: boolean;
  showSkuField?: boolean;
  limit?: number;
  offset?: number;
}
const USER_ID_POSTFIX = `preview-user-id`;
const SKU_KEY_POSTFIX = `preview-sku`;

export default function usePreview({ feedId, feedPreviewType, showIdField, showSkuField }: UsePreviewProps) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[pagination.LIMITS.length - 1]);
  const [uuid, setUuid] = useState("");
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const SKU_KEY = `${selectedEnvironmentProjectId}-${SKU_KEY_POSTFIX}`;
  const USER_ID_KEY = `${selectedEnvironmentProjectId}-${USER_ID_POSTFIX}`;
  const [sku = "", setSku] = useCachedState(SKU_KEY, showSkuField);
  const [userID = "", setUserID] = useCachedState(USER_ID_KEY, showIdField);

  const [getFeedPreview, { data, loading, previousData }] = useCustomFeedPreviewLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        feedID: feedId,
        feedPreviewType,
        projectId: selectedEnvironmentProjectId,
        sku,
        userID,
        limit,
        offset,
      },
    },
  });

  const previewData = (data ?? previousData)?.customFeedPreview;

  useEffect(() => {
    const timerId = setTimeout(() => {
      getFeedPreview({
        variables: {
          params: {
            feedID: feedId,
            feedPreviewType,
            projectId: selectedEnvironmentProjectId,
            sku,
            userID,
            limit,
            offset,
          },
        },
      });
    }, 1);

    return () => {
      clearTimeout(timerId);
    };
  }, [uuid, offset, limit]);

  return {
    getFeedPreview,
    previewData,
    setUuid,
    uuid,
    sku,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
    setSku,
    setUserID,
  };
}
